<template>
  <div class="bitcoin">
    <div v-if="myBtc.price">
      1 BTC =
      {{ myBtc.price.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }}
      €
      <hr />
      <input
        class="amount-input"
        type="number"
        v-model="amount"
        max="1000000"
        maxlength="10"
        step="0.00000001"
      />
      <div class="res">
        {{
          (myBtc.price * amount)
            .toFixed(2)
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
        }}
        €
      </div>
    </div>
    <div v-if="!myBtc.price">
      Waiting for websocket connection
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Bitcoin",
  data() {
    return {
      patr: 1050,
      amount: 1
    };
  },
  methods: {
    ...mapActions(["initWebsocket"])
  },
  computed: mapGetters(["myBtc"]),
  created() {
    this.initWebsocket();
  }
};
</script>

<style scoped>
.amount-input {
  height: 36px;
  width: 200px;
  font-size: 24px;
  text-align: center;
  border: 4px solid cornsilk;
  border-radius: 25px;
  outline-style: none;
  background-color: #f15946;
}
.res {
  font-size: 42px;
  text-align: center;
  padding-top: 42px;
}
</style>
